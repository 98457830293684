import React from 'react'
import classnames from 'classnames'
import { GatsbyImage } from 'gatsby-plugin-image'
import * as styles from './styles.module.scss'

const BackgroundImg = props => (
  <GatsbyImage
    className={classnames(styles.bgImg, props.className)}
    {...props}
  />
)

export default BackgroundImg
