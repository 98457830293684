import React from 'react'
import { connect } from 'react-redux'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PricingTilesCSGO from '../components/pricing/pricingTilesCSGO'
import Partnerships from '../components/about/Partnerships'
import CookieNotice from '../components/shared/CookieNotice'

const Pricing = ({ declarePageAsNotLanding }) => {
  declarePageAsNotLanding()
  return (
    <Layout>
      <SEO
        title='Pricing Shadow Dota'
        description='First dedicated, multi-title esports analytics solution. We are the esports analytics professionals - The Shadow team includes experts dedicated to product design, development, testing, security, and client support.'
        keywords={[
          `shadow`,
          `esports`,
          `analytics`,
          `esports teams`,
          `teams`,
          `professional`,
          `csgo`,
          `cs:go`,
          `dota2`,
          `dota`,
          `dota 2`,
          `lol`,
          `league of legends`,
        ]}
      />
      <CookieNotice />
      <h1>Pricing | Shadow CS:GO</h1>
      <PricingTilesCSGO />
      <Partnerships />
    </Layout>
  )
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    declarePageAsNotLanding: () => dispatch({ type: `SET_LANDING_PAGE_FALSE` }),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Pricing)
