// import React from 'react'
// import classnames from 'classnames'
// import { StaticQuery, graphql } from 'gatsby'
// import { GatsbyImage } from 'gatsby-plugin-image'
// import * as styles from './styles.module.scss'

const Partnerships = () => null
/*
  <StaticQuery
    query={graphql`
      query {
        battlefy: file(relativePath: { eq: "l-battlefy@3x.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 68) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        eLeague: file(relativePath: { eq: "l-eleague@3x.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 78) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => (
      <section className={classnames(styles.wrapper, 'col-centered')}>
        <div className={styles.text}>
          <h4>with them we go the distance</h4>
          <h2>Our Partnerships</h2>
        </div>
        <div className={styles.partners}>
          <div className={styles.imgWrapper}>
            <Img
              fluid={data.eLeague.childImageSharp.fluid}
              className={styles.eLeague}
            />
          </div>
          <div className={styles.imgWrapper}>
            <Img
              fluid={data.battlefy.childImageSharp.fluid}
              className={styles.battlefy}
            />
          </div>
        </div>
      </section>
    )}
  />
*/

export default Partnerships
