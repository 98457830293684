// extracted by mini-css-extract-plugin
export var backgroundImg = "styles-module--backgroundImg--mYNFH";
export var bottomContainer = "styles-module--bottomContainer--5nSi4";
export var buttonContainer = "styles-module--buttonContainer--MCTAN";
export var card = "styles-module--card--bESWM";
export var cardsContainer = "styles-module--cardsContainer--WbBB6";
export var cent = "styles-module--cent--nX0xS";
export var centreCard = "styles-module--centreCard--WHM6j";
export var container = "styles-module--container--PRnZ9";
export var demoButtonContainer = "styles-module--demoButtonContainer--Acc2i";
export var disabledBtnContainer = "styles-module--disabledBtnContainer--RgFy3";
export var enterprise = "styles-module--enterprise--c84nL";
export var featureContainer = "styles-module--featureContainer--1F5Nh";
export var featureList = "styles-module--featureList--8kGeR";
export var footerMsg = "styles-module--footerMsg--YuR9+";
export var heroWrapper = "styles-module--heroWrapper--GDrzz";
export var imageContainer = "styles-module--imageContainer--jIEuX";
export var infoIcon = "styles-module--infoIcon--3mplP";
export var leaveEmail = "styles-module--leaveEmail--CXf+Y";
export var logoMobile = "styles-module--logoMobile--tUvOD";
export var month = "styles-module--month--vYCkw";
export var offer = "styles-module--offer--ExzQg";
export var onRequest = "styles-module--onRequest--i1JOd";
export var planFooter = "styles-module--planFooter--FKiRw";
export var planIntro = "styles-module--planIntro--BnBrF";
export var planMonth = "styles-module--planMonth--xi9FK";
export var productTabsHeader = "styles-module--productTabsHeader--ukgrS";
export var schemeInfo = "styles-module--schemeInfo--Lr+15";
export var slider = "styles-module--slider--mUe5X";
export var switchi = "styles-module--switchi--mmGD4";
export var theTeamWrapper = "styles-module--theTeamWrapper--+C9XO";
export var tierName = "styles-module--tierName--EFZid";
export var tierPrice = "styles-module--tierPrice--ixEk5";
export var tierPriceContainer = "styles-module--tierPriceContainer--g+G4W";
export var tierPriceUnit = "styles-module--tierPriceUnit--9ZA91";
export var tooltipInfoText = "styles-module--tooltipInfoText--mIFPq";
export var tooltiptext = "styles-module--tooltiptext--Vph1f";
export var topContainer = "styles-module--topContainer--cukgl";
export var unitEur = "styles-module--unitEur--+kzKS";
export var unitMo = "styles-module--unitMo--1V4Rd";
export var wrapContainer = "styles-module--wrapContainer--IKDM-";